<template>

    

    <div style="padding:20px;">

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>
                <div style="">
                     <v-container class="">
                        <v-row>
                          <v-col>
                                <v-select color v-model="categoriaselecionada" :items="listadecategorias" filled label="Categoria"
                                    item-text="nome" item-value="id">
                                </v-select>
                          </v-col>
                          <v-col>
                            <v-text-field type="text" label="Titulo" v-model="titulo" outlined color></v-text-field>
                            
                          </v-col>
                          <!-- <v-col>
                            <a href="">Criar Nova Categoria</a>
                          </v-col> -->
                        </v-row>
                        <v-row>
                          <v-col>
                                <vue-editor id="descricao" v-model="artigotexto"></vue-editor>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                          <v-col></v-col>
                          <v-col>
                                <v-btn large block elevation="" color="info" @click="criarArtigo()" >Salvar</v-btn>                            
                          </v-col>
                        </v-row>
                      </v-container>
                    
                    
                </div>
            </v-card-text>               
        </v-card>



    </div> 

    
</template>

<script>

import { VueEditor } from 'vue2-editor'
import api from '../services/api'
const config = { headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`} };


export default {
    name:'Robos',
    components: {
        VueEditor
    },
    data(){
        return{
            artigotexto:null,
            categoriaselecionada:null,
            listadecategorias:[],
            titulo:null,
        }
    },
    watch: {
        
    },
    methods:{
        buscarCategorias(){
            api().post('/artigo/buscarCategorias', {id:null}, config).then(resp=>{
                this.listadecategorias = resp.data
            })
        },
        criarArtigo(){
            let body = {
                idcategoria:this.categoriaselecionada,
                texto:this.artigotexto,
                titulo:this.titulo,
            }
            api().post('/artigo/criarArtigo', body, config).then(resp=>{
                alert('Salvo com Sucesso!')
                console.log('resp ',resp)
                this.categoriaselecionada = null
                this.artigotexto = null
                this.titulo = null
            })
        }
    },

    
    async mounted(){
        await this.buscarCategorias()
    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>